import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import { Link } from 'gatsby';
import "./index.css"
function BlogSidebar() {
  const data = useStaticQuery(
    graphql`
      query {
        allStrapiBlog(limit: 10, sort: {fields: date, order: DESC}) {
          edges {
            node {
              title
              slug
            }
          }
        }
        allStrapiTag {
          edges {
            node {
              id
              slug
              blogs {
                id
              }
              name
            }
          }
        }
      }
    `
  );

  const recentBlogs = data.allStrapiBlog?.edges?.map(e => e.node) ?? [];
  const categories = data.allStrapiTag?.edges?.map(e => {
    return { name: e.node.name, "length": e.node.blogs.length, slug: e.node.slug }
  }
  ) ?? [];


  return (
    <div className='w-full  md:min-w-[250px] lg:max-w-[300px]'>
      <h3 className="dl-h3 text-left">Recent Posts</h3>
      <ul className='list-disc list-inside'>
        {recentBlogs.map(blog => <li key={blog.slug} className="hover:text-dll-hover pt-1">
          <Link to={`/${blog.slug}/`}>{blog.title}</Link>
        </li>)}
      </ul>
      <h3 className="dl-h3 text-left mt-6">Categories</h3>
      <ul>
        {categories.map(category => <li key={category.slug} className="categoryItems hover:text-dll-hover pt-1">
          <Link to={`/category/${category.slug}/`}>{`${category.name} (${category.length})`}</Link>
        </li>)}
      </ul>
    </div>
  )
}



export default BlogSidebar;