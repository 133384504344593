import * as React from "react"
import Meta from '../components/meta';
import Layout from '../components/layout';
import DummyHero from '../components/dummy-hero';
import { graphql } from "gatsby";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import CustomLink from "../components/custom-link"
import BlogSideBar from "../components/blog-sidebar";
import "./blog.css"
import { AiFillFacebook, AiFillTwitterSquare, AiFillLinkedin } from "react-icons/ai";




const BlogTemplate = ({ data }) => {

  const blog = data.strapiBlog;
  const medias = blog.body.medias;
  const seo = blog.seo;

  let meta = {
    title: `${seo.title}`,
    description: seo.description,
    url: `https://www.digitallaboratory.net/${blog.slug}/`,
    image: seo.image.localFile.childImageSharp.fixed.src
  };

  return (
    <Layout>
      <Meta meta={meta} />
      <DummyHero title="Our Blogs" />
      <div className="mainConainer">
        <div className="mainBody">
          <span className="mainBody__date">{blog.date}</span> {" "}
          {/* Anchor? */}
          <p className="mainBody__tags">{blog.tags.map((tag) => <Link key={tag.slug} to={`/category/${tag.slug}/`}><span className="mainBody__tag">{tag.name}</span></Link>)}</p>
          <h2 className="mt-4 text-left dl-h2">{blog.title}</h2>
          <div className="mainBody__divider"></div>
          <article className="prose prose-h3:dl-h3 prose-h4:dl-h4 prose-h3:text-left prose-h4:text-left prose-p:dl-p2 marker:text-[#3B3B3B] prose-a:dl-link">
            <ReactMarkdown components={getComponents(medias)}>
              {blog.body.data.body}
            </ReactMarkdown>
          </article>

          <div className="socialShare">
            <a href={`https://www.facebook.com/sharer.php?u=https://www.digitallaboratory.net/${blog.slug}`} target="_blank" rel="noreferrer" aria-label="DigitalLaboratory Facebook Share" className="socialShare__facebook socialShare__link">
              <AiFillFacebook/>
            </a>
            
            <a href={`https://twitter.com/share?text=https://www.digitallaboratory.net/${blog.slug}`} target="_blank" rel="noreferrer" aria-label="DigitalLaboratory Twitter Share" className="socialShare__twitter socialShare__link">
              <AiFillTwitterSquare/>
            </a>

            <a href={`https://www.linkedin.com/sharing/share-offsite/?url=https://www.digitallaboratory.net/${blog.slug}`} target="_blank" rel="noreferrer" aria-label="DigitalLaboratory LinkedIn Share" className="socialShare__linkedin socialShare__link">
            <AiFillLinkedin/>
            </a>
          </div>



        </div>
        <BlogSideBar />

      </div>




    </Layout>
  )
}

const getComponents = (meidas) => {
  return {
    a: CustomLink,
    p: ({ node, ...props }) => {
      try {
        // console.log(node.children);
        // This function is perform to avoide <p><div></div></p> div tag inside p
        const imgList = node.children.filter(node => node.type === "element" && node.tagName === "img");
        if (imgList.length > 0) {
          return <div className="paragraph" {...props}></div>
        }

      } catch (error) {
        console.log(error);
      }
      return <p {...props}></p>
    },
    img: ({ node, ...props }) => {
      const media = meidas.filter(meida => meida.localFile.url.includes(props.src))[0];
      try {
        return <GatsbyImage
          alt={media.alternativeText}
          title={media.alternativeText}
          image={getImage(media.localFile)}
          className="image" 
          />;
      }
      catch (e) {
        console.error(e);
        return <></>;
      }
    }
  };
}


export const Head = ({data}) => {
  // console.log("blogs: ", blogs);

  const blog = data.strapiBlog;
  const medias = blog.body.medias;
  const seo = blog.seo;

  let meta = {
    title: `${seo.title}`,
    description: seo.description,
    url: `https://www.digitallaboratory.net/${blog.slug}/`,
    image: seo.image.localFile.childImageSharp.fixed.src
  };

  return (
    <Meta meta={meta} />
  );
};

export const query = graphql`
query ($id: String) {
  strapiBlog(id: {eq: $id}) {
    title
    date(formatString: "MMMM D, YYYY")
    slug
    tags {
      name
      slug
    }
    cover {
      localFile {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1.77)
        }
      }
    }
    body {
      data {
        body
      }
      medias {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
          url
        }
        alternativeText
      }
    }
    seo {
      title
      description
      image {
        localFile {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
    }
  }

}
    
`;

export default BlogTemplate;